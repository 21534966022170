// import store from "@/state/store";

export default [
  {
    path: "/404",
    name: "404",
    meta: { isCheck: false },
    component: () => import("./views/utility/404.vue"),
  },
  {
    path: "/403",
    name: "403",
    meta: { isCheck: false },
    component: () => import("./views/utility/403.vue"),
  },
  {
    path: "/login",
    name: "login",
    meta: { isCheck: false },
    component: () => import("./views/account/login"),
  },
  {
    path: "/login-penilai",
    name: "login-penilai",
    meta: { isCheck: false },
    component: () => import("./views/account/login-penilai.vue"),
  },
  {
    path: "/login-peserta",
    name: "login-peserta",
    meta: { isCheck: false },
    component: () => import("./views/account/login-peserta.vue"),
  },
  {
    path: "/",
    name: "dashboard",
    meta: { authRequired: true, isCheck: true, label: "Jabatan Kompetensi" },
    component: () =>
      import(
        "./views/master-kompetensi/jabatan-kompetensi/all-jabatan_kompetensi.vue"
      ),
  },
  {
    path: "/profile",
    name: "profile",
    meta: { authRequired: true, isCheck: false },
    component: () => import("./views/account/profile"),
  },
  {
    path: "/logout",
    name: "logout",
    meta: { authRequired: true, isCheck: false },
    component: () => import("./views/account/logout"),
  },

  // routes Master

  // routes Timer
  {
    path: "/master/timer",
    name: "all-timer",
    meta: { authRequired: true, isCheck: true, label: "Konfigurasi Timer" },
    component: () => import("./views/master-timer/all-master-timer"),
  },

  {
    path: "/master/treshold-nilai",
    name: "all-treshold-nilai",
    meta: { authRequired: true, isCheck: true, label: "Treshold Nilai" },
    component: () =>
      import("./views/master-treshold-nilai/all-master-tresholdnilai"),
  },

  // routes master role
  {
    path: "/master/role",
    name: "all-role",
    meta: { authRequired: true, isCheck: false },
    component: () => import("./views/master/role/all-role"),
  },
  {
    path: "/master/role/tambah",
    name: "add-role",
    meta: { authRequired: true, isCheck: false },
    component: () => import("./views/master/role/add-role"),
  },
  {
    path: "/master/role/edit/:id",
    name: "edit-role",
    meta: { authRequired: true, isCheck: false },
    component: () => import("./views/master/role/edit-role"),
  },

  // routes master menu
  {
    path: "/master/menu",
    name: "all-menu",
    meta: { authRequired: true, isCheck: false },
    component: () => import("./views/master/menu/all-menu"),
  },
  {
    path: "/master/menu/tambah",
    name: "add-menu",
    meta: { authRequired: true, isCheck: false },
    component: () => import("./views/master/menu/add-menu"),
  },
  {
    path: "/master/menu/edit/:id",
    name: "edit-menu",
    meta: { authRequired: true, isCheck: false },
    component: () => import("./views/master/menu/edit-menu"),
  },
  {
    path: "/master/menu/ordering",
    name: "ordering-menu",
    meta: { authRequired: true, isCheck: false },
    component: () => import("./views/master/menu/ordering-menu"),
  },
  {
    path: "/master/menu/role-menu",
    name: "role-menu",
    meta: { authRequired: true, isCheck: false },
    component: () => import("./views/master/menu/role-menu"),
  },

  // routes master user
  {
    path: "/master/user",
    name: "all-user",
    meta: { authRequired: true, isCheck: false },
    component: () => import("./views/master/user/all-user"),
  },
  {
    path: "/master/user/tambah",
    name: "add-user",
    meta: { authRequired: true, isCheck: false },
    component: () => import("./views/master/user/add-user"),
  },
  {
    path: "/master/user/edit/:id",
    name: "edit-user",
    meta: { authRequired: true, isCheck: false },
    component: () => import("./views/master/user/edit-user"),
  },

  // routes master konfigurasi
  {
    path: "/master/konfigurasi",
    name: "all-konfigurasi",
    meta: { authRequired: true, isCheck: false },
    component: () => import("./views/master/konfigurasi/all-konfigurasi"),
  },

  //routes master pohon profesi
  {
    path: "/master/pohon-profesi",
    name: "all-pohon-profesi",
    meta: { authRequired: true, isCheck: false },
    component: () => import("./views/master/pohon-profesi/all-pohon-profesi"),
  },

  //routes master menu page
  {
    path: "/master/page",
    name: "all-page",
    meta: { authRequired: true, isCheck: false },
    component: () => import("./views/master/page/all-page"),
  },
  {
    path: "/master/page/tambah",
    name: "add-page",
    meta: { authRequired: true, isCheck: false },
    component: () => import("./views/master/page/add-page"),
  },
  {
    path: "/master/page/edit/:id",
    name: "edit-page",
    meta: { authRequired: true, isCheck: false },
    component: () => import("./views/master/page/edit-page"),
  },

  //routes master menu detail page
  // {
  //   path: "/master/detail-page/:id",
  //   name: "detail-page",
  //   meta: { authRequired: true },
  //   component: () => import("./views/master/detail-page/all-detail-page"),
  // },

  // (WIP) routes menu dynamic render
  {
    path: "/render",
    name: "render",
    meta: { authRequired: true, isCheck: false },
    component: () => import("./views/render"),
  },

  // Master Kompetensi
  {
    path: "/master-kompetensi/jabatan-kompetensi",
    name: "jabatan-kompetensi",
    meta: { authRequired: true, isCheck: true, label: "Jabatan Kompetensi" },
    component: () =>
      import(
        "./views/master-kompetensi/jabatan-kompetensi/all-jabatan_kompetensi.vue"
      ),
  },

  {
    path: "/master-kompetensi/kategori-studi-kasus",
    name: "kategori-studi-kasus",
    meta: { authRequired: true, isCheck: true, label: "Kategori Studi Kasus" },
    component: () =>
      import(
        "./views/master-kompetensi/kategori-studi-kasus/all-kategori_studi_kasus.vue"
      ),
  },

  {
    path: "/master-kompetensi/kompetensi",
    name: "kompetensi",
    meta: { authRequired: true, isCheck: true, label: "Kompetensi" },
    component: () =>
      import("./views/master-kompetensi/kompetensi/all-kompetensi.vue"),
  },
  {
    path: "/master-kompetensi/kompetensi/:id/soal-pilihan-ganda",
    name: "soal-pg",
    meta: { authRequired: true, isCheck: true, label: "Kompetensi" },
    component: () =>
      import(
        "./views/master-kompetensi/kompetensi/master-soal/all-pilihan-ganda.vue"
      ),
  },
  {
    path: "/master-kompetensi/kompetensi/:id/soal-pilihan-ganda/create",
    name: "soal-pg-create",
    meta: { authRequired: true, isCheck: true, label: "Kompetensi" },
    component: () =>
      import(
        "./views/master-kompetensi/kompetensi/master-soal/form-pilihan-ganda.vue"
      ),
  },
  {
    path: "/master-kompetensi/kompetensi/:id/soal-pilihan-ganda/:idsoal",
    name: "soal-pg-update",
    meta: { authRequired: true, isCheck: true, label: "Kompetensi" },
    component: () =>
      import(
        "./views/master-kompetensi/kompetensi/master-soal/form-pilihan-ganda.vue"
      ),
  },
  {
    path: "/master-kompetensi/kompetensi/:id/soal-text",
    name: "soal-text",
    meta: { authRequired: true, isCheck: true, label: "Kompetensi" },
    component: () =>
      import(
        "./views/master-kompetensi/kompetensi/master-soal/all-soal-text.vue"
      ),
  },
  {
    path: "/master-kompetensi/kompetensi/:id/soal-text/create",
    name: "soal-text-create",
    meta: { authRequired: true, isCheck: true, label: "Kompetensi" },
    component: () =>
      import(
        "./views/master-kompetensi/kompetensi/master-soal/form-soal-text.vue"
      ),
  },
  {
    path: "/master-kompetensi/kompetensi/:id/soal-text/:idsoal",
    name: "soal-text-update",
    meta: { authRequired: true, isCheck: true, label: "Kompetensi" },
    component: () =>
      import(
        "./views/master-kompetensi/kompetensi/master-soal/form-soal-text.vue"
      ),
  },
  {
    path: "/master-kompetensi/kompetensi/:id/soal-jodoh",
    name: "soal-jodoh",
    meta: { authRequired: true, isCheck: true, label: "Kompetensi" },
    component: () =>
      import(
        "./views/master-kompetensi/kompetensi/master-soal/all-soal-jodoh.vue"
      ),
  },
  {
    path: "/master-kompetensi/kompetensi/:id/soal-jodoh/:idsoal",
    name: "soal-jodoh-update",
    meta: { authRequired: true, isCheck: true, label: "Kompetensi" },
    component: () =>
      import(
        "./views/master-kompetensi/kompetensi/master-soal/form-soal-jodoh.vue"
      ),
  },
  {
    path: "/master-kompetensi/kompetensi/:id/soal-benar-salah",
    name: "soal-benar-salah",
    meta: { authRequired: true, isCheck: true, label: "Kompetensi" },
    component: () =>
      import(
        "./views/master-kompetensi/kompetensi/master-soal/all-soal-benar-salah.vue"
      ),
  },
  {
    path: "/master-kompetensi/kompetensi/:id/soal-benar-salah/create",
    name: "soal-benar-salah-create",
    meta: { authRequired: true, isCheck: true, label: "Kompetensi" },
    component: () =>
      import(
        "./views/master-kompetensi/kompetensi/master-soal/form-soal-benar-salah.vue"
      ),
  },
  {
    path: "/master-kompetensi/kompetensi/:id/soal-benar-salah/:idsoal",
    name: "soal-benar-salah-update",
    meta: { authRequired: true, isCheck: true, label: "Kompetensi" },
    component: () =>
      import(
        "./views/master-kompetensi/kompetensi/master-soal/form-soal-benar-salah.vue"
      ),
  },
  {
    path: "/master-kompetensi/kompetensi/soal-kompetensi-pilihan-ganda",
    name: "soal-kompetensi-pilihan-ganda",
    meta: { authRequired: true, isCheck: true, label: "Kompetensi" },
    component: () =>
      import(
        "./views/master-kompetensi/kompetensi/soal-kompetensi_pilihan_ganda.vue"
      ),
  },
  {
    path: "/master-kompetensi/kompetensi/soal-kompetensi-menjodohkan",
    name: "soal-kompetensi-menjodohkan",
    meta: { authRequired: true, isCheck: true, label: "Kompetensi" },
    component: () =>
      import(
        "./views/master-kompetensi/kompetensi/soal-kompetensi_menjodohkan.vue"
      ),
  },
  {
    path: "/master-kompetensi/kompetensi/soal-kompetensi-text",
    name: "soal-kompetensi-text",
    meta: { authRequired: true, isCheck: true, label: "Kompetensi" },
    component: () =>
      import("./views/master-kompetensi/kompetensi/soal-kompetensi_text.vue"),
  },

  {
    path: "/master-kompetensi/kompetensi/tambah-pilihan-ganda",
    name: "tambah-pilihan-ganda",
    meta: { authRequired: true, isCheck: true, label: "Kompetensi" },
    component: () =>
      import("./views/master-kompetensi/kompetensi/tambah_pilihan_ganda.vue"),
  },
  {
    path: "/master-kompetensi/kompetensi/tambah-text",
    name: "tambah-text",
    meta: { authRequired: true, isCheck: true, label: "Kompetensi" },
    component: () =>
      import("./views/master-kompetensi/kompetensi/tambah_text.vue"),
  },

  // Master Soal
  {
    path: "/master-soal/kategori-soal",
    name: "kategori-soal",
    meta: { authRequired: true, isCheck: false },
    component: () =>
      import("./views/master-soal/kategori-soal/all-kategori_soal.vue"),
  },
  {
    path: "/report-nilai-kompetensi",
    name: "report-nilai-kompetensi",
    meta: {
      authRequired: true,
      isCheck: true,
      label: "Report Nilai Kompetensi",
    },
    component: () => import("./views/report/report-nilai-kompetensi.vue"),
  },
  {
    path: "/report-nilai-jabatan",
    name: "report-nilai-jabatan",
    meta: { authRequired: true, isCheck: true, label: "Report Nilai Jabatan" },
    component: () => import("./views/report/report-nilai-jabatan.vue"),
  },
  {
    path: "/report-hasil",
    name: "report-hasil",
    meta: { authRequired: true, isCheck: true, label: "Report Hasil" },
    component: () => import("./views/report/report-hasil/report-hasil.vue"),
  },
  {
    path: "/report-jadwal",
    name: "report-jadwal",
    meta: { authRequired: true, isCheck: true, label: "Report Jadwal" },
    component: () => import("./views/report/report-jadwal.vue"),
  },
  {
    path: "/master-soal/konfigurasi-soal",
    name: "konfigurasi-soal",
    meta: { authRequired: true, isCheck: false },
    component: () =>
      import("./views/master-soal/konfigurasi-soal/all-konfigurasi_soal.vue"),
  },
  {
    path: "/master-soal/konfigurasi-soal/edit-soal",
    name: "edit-soal",
    meta: { authRequired: true, isCheck: false },
    component: () =>
      import("./views/master-soal/konfigurasi-soal/all-edit_soal.vue"),
  },
  {
    path: "/master-soal/keyword-essai",
    name: "keyword-essai",
    meta: { authRequired: true, isCheck: false },
    component: () =>
      import("./views/master-soal/keyword-essai/all-keyword_essai.vue"),
  },

  // Master Soal Kompetensi
  {
    path: "/master-soal-kompetensi",
    name: "master-soal-kompetensi",
    meta: { authRequired: true, isCheck: false },
    component: () =>
      import("./views/master-soal-kompetensi/all-master_soal_kompetensi.vue"),
  },

  // Assesmen HC
  {
    path: "/assesmen-hc/dashboard-assesmen-hc",
    name: "dashboard-assesmen-hc",
    // meta: { authRequired: true, isCheck: false,},
    component: () => import("./views/assesmen-hc/dashboard_assesmen_hc.vue"),
  },

  {
    path: "/assesmen-hc/dashboard-soal",
    name: "dashboard-soal",
    meta: { authPesertaRequired: false, isCheck: false },
    component: () => import("./views/assesmen-hc/dashboard_soal.vue"),
  },

  {
    path: "/assesmen-hc/pilihan-ganda",
    name: "pilihan-ganda",
    meta: { authPesertaRequired: true, isCheck: false },
    component: () =>
      import("./views/assesmen-hc/pilihan-ganda/petunjuk_pilihan_ganda.vue"),
  },
  {
    path: "/assesmen-hc/pilihan-ganda/soal-pilihan-ganda",
    name: "soal-pilihan-ganda",
    meta: { authPesertaRequired: true, isCheck: false },
    component: () =>
      import("./views/assesmen-hc/pilihan-ganda/soal_pilihan_ganda.vue"),
  },

  {
    path: "/assesmen-hc/salah-benar",
    name: "salah-benar",
    meta: { authPesertaRequired: true, isCheck: false },
    component: () =>
      import("./views/assesmen-hc/salah-benar/petunjuk_salah_benar.vue"),
  },
  {
    path: "/assesmen-hc/salah-benar/soal-salah-benar",
    name: "soal-salah-benar",
    meta: { authPesertaRequired: true, isCheck: false },
    component: () =>
      import("./views/assesmen-hc/salah-benar/soal_salah_benar.vue"),
  },

  {
    path: "/assesmen-hc/pg-dengan-narasi",
    name: "pg-dengan-narasi",
    meta: { authPesertaRequired: true, isCheck: false },
    component: () =>
      import(
        "./views/assesmen-hc/pg-dengan-narasi/petunjuk_pg_dengan_narasi.vue"
      ),
  },
  {
    path: "/assesmen-hc/pg-dengan-narasi/soal-1-pg-dengan-narasi",
    name: "soal-1-pg-dengan-narasi",
    meta: { authPesertaRequired: true, isCheck: false },
    component: () =>
      import(
        "./views/assesmen-hc/pg-dengan-narasi/soal-1_pg_dengan_narasi.vue"
      ),
  },
  {
    path: "/assesmen-hc/pg-dengan-narasi/soal-2-pg-dengan-narasi",
    name: "soal-2-pg-dengan-narasi",
    meta: { authPesertaRequired: true, isCheck: false },
    component: () =>
      import(
        "./views/assesmen-hc/pg-dengan-narasi/soal-2_pg_dengan_narasi.vue"
      ),
  },

  {
    path: "/assesmen-hc/menjodohkan",
    name: "menjodohkan",
    meta: { authPesertaRequired: true, isCheck: false },
    component: () =>
      import("./views/assesmen-hc/menjodohkan/petunjuk_menjodohkan.vue"),
  },
  {
    path: "/assesmen-hc/menjodohkan/soal-menjodohkan",
    name: "soal-menjodohkan",
    meta: { authPesertaRequired: true, isCheck: false },
    component: () =>
      import("./views/assesmen-hc/menjodohkan/soal_menjodohkan.vue"),
  },

  {
    path: "/assesmen-hc/informasi-soal",
    name: "informasi-soal",
    meta: { authPesertaRequired: true, isCheck: false },
    component: () => import("./views/assesmen-hc/informasi_soal.vue"),
  },

  {
    path: "/assesmen-hc/survey",
    name: "survey",
    meta: { authPesertaRequired: true, isCheck: false },
    component: () => import("./views/assesmen-hc/survey2.vue"),
  },

  {
    path: "/assesmen-hc/unit-pelaksanaan-sertifikasi/list-soal",
    name: "list-soal",
    meta: { authPesertaRequired: true, isCheck: false },
    component: () =>
      import("./views/assesmen-hc/unit-pelaksanaan-sertifikasi/list_soal.vue"),
  },

  {
    path: "/master-penjadwalan/jadwal-pelaksanaan",
    name: "all-jadwal-pelaksanaan",
    meta: { authRequired: true, isCheck: true, label: "Jadwal Pelaksanaan" },
    component: () =>
      import(
        "./views/master-penjadwalan/jadwal-pelaksanaan/all-jadwal-pelaksanaan.vue"
      ),
  },
  {
    path: "/master-penjadwalan/jadwal-pelaksanaan/monitor-jadwal/:idjadwal",
    name: "monitor-jadwal-pelaksanaan",
    meta: { authRequired: true, isCheck: true, label: "Jadwal Pelaksanaan" },
    component: () =>
      import(
        "./views/master-penjadwalan/jadwal-pelaksanaan/monitor-jadwal.vue"
      ),
  },
  {
    path: "/master-penjadwalan/jadwal-pelaksanaan/setup-jadwal/:id",
    name: "setup-jadwal",
    meta: { authRequired: true, isCheck: true, label: "Jadwal Pelaksanaan" },
    component: () =>
      import(
        "./views/master-penjadwalan/jadwal-pelaksanaan/setup-jadwal/setup-jadwal.vue"
      ),
  },
  {
    path: "/master-penjadwalan/jadwal-pelaksanaan/setup-jadwal/:id/setup-batch/:idbatch",
    name: "setup-jadwal-batch",
    props: true,
    meta: { authRequired: true, isCheck: true, label: "Jadwal Pelaksanaan" },
    component: () =>
      import(
        "./views/master-penjadwalan/jadwal-pelaksanaan/setup-jadwal/tab-batch/setup-batch/setup-batch.vue"
      ),
  },
  {
    path: "/master-penjadwalan/jadwal-pelaksanaan/setup-jadwal/:id/view-batch/:idbatch",
    name: "detail-jadwal-batch",
    meta: { authRequired: true },
    props: { isView: true, isCheck: true, label: "Jadwal Pelaksanaan" },
    component: () =>
      import(
        "./views/master-penjadwalan/jadwal-pelaksanaan/setup-jadwal/tab-batch/setup-batch/setup-batch.vue"
      ),
  },
  {
    path: "/log-peserta",
    name: "log-login-peserta",
    meta: { authRequired: true, isCheck: true, label: "Log Peserta" },
    component: () => import("./views/log-peserta/log-peserta.vue"),
  },

  // penilai
  {
    path: "/penilai/dashboard-penilai",
    name: "penilai-dashboard",
    meta: { authRequired: true, isCheck: false },
    component: () => import("./views/penilai/dashboard-penilai/dashboard.vue"),
  },
  {
    path: "/penilai/peserta",
    name: "penilai-peserta",
    meta: { authRequired: true, isCheck: false },
    component: () => import("./views/penilai/peserta/list-peserta.vue"),
  },
  {
    path: "/penilai/soal-peserta",
    name: "penilai-soal-peserta",
    meta: { authRequired: true, isCheck: false },
    component: () =>
      import("./views/penilai/soal-peserta/list-soal-peserta.vue"),
  },
  {
    path: "/file-manager",
    name: "file-manager",
    meta: { authRequired: true, isCheck: true, label: "File Manager" },
    component: () => import("./views/file-manager/all-file-manager.vue"),
  },
  {
    path: "/info-ujian",
    name: "info-ujian",
    meta: { authRequired: true, isCheck: true, label: "Informasi Ujian" },
    component: () => import("./views/informasi-ujian/info-ujian.vue"),
  },
];
